import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
        "Home": "Home",
        "About": "About",
        "Portfolio": "Portfolio",
        "Contact": "Contact",

        "Photography Portfolio": "Portfolio",
        "View details for": "View details for",

        "CREATE MAGIC": "WITH YOU ,WE CREATE MAGIC",
        "wedding film": "Wedding photographer and cinematic wedding film",
        "Contact us": "Contact us",
        "Contact information": "Contact information",
        "contact description1": `Let's make connections, talk about yourself, and your day, and have a great time together.`,

        "contact description2": "We live and breath our beautiful “job” and this is what we want to share with you.",

        "contact description3": "We always try to give you a 48-hour answer. Of course, especially at the wedding. If you have heard nothing after 72h please email us again!",

        "Address": "Address",
        "Phone number": "Phone number",
        "Email": "Email",
        "Send us a message": "Send us a message",
        "Im Interested In": "I'm Interested In",
        "PHOTOGRAPHY": "PHOTOGRAPHY",
        "VIDEOGRAPHY": "VIDEOGRAPHY",
        "BOTH PHOTO VIDEO": "BOTH PHOTO + VIDEO",
        "First name": "First name",
        "Last name": "Last name",
        "Phone": "Phone",
        "Budget": "Budget",
        "Optional": "Optional",
        "Sending": "Sending",
        "Submit": "Submit",
        "Line and Noah": "Line and Noah are a couple who are passionate about image, creativity, and love.",
        "Together we founded": "Together, we founded",

        "about1": 'in 2019 and have poured our passion into making it our "baby."',
        "about2": "Our films and photos are inspired by cinematographic methods and compositions, and we strive to reflect the unique style and personality of each wedding through our work.",
        "about3": "We find the most rewarding part of our job is capturing those tender, timeless moments and turning them into a story that will be told and experienced again and again.",
        "about4": ", we take pride in making every project a reflection of our passion and love. We capture life's simplest moments and make them magical.",
        "about5": "If you want to know more about us, write to us, we will be happy to talk to you.",

    }
  },
  fr: {
    translation: {
        "Home": "Accueil",
        "About": "A Propos",
        "Portfolio": "Portfolio",
        "Contact": "Contact",

        "Photography Portfolio": "Portfolio",
        "View details for": "Afficher les détails pour",

        "CREATE MAGIC": "AVEC VOUS, NOUS CRÉONS DE LA MAGIE",
        "wedding film": "Photographe de mariage et film de mariage cinématographique",
        "Contact us": "Contactez-nous",
        "Contact information": "Informations de contact",
        "contact description1": `Établissons des liens, parlons de vous et de votre journée, et passons un bon moment ensemble.`,

        "contact description2": "Nous vivons et respirons notre beau travail et c'est ce que nous voulons partager avec vous.",

        "contact description3": "Nous essayons toujours de vous donner une réponse de 48 heures. Bien sûr, surtout au mariage. Si vous n'avez rien entendu après 72h s'il vous plaît envoyez-nous un courriel à nouveau!",

        "Address": "Adresse",
        "Phone number": "Numéro de téléphone",
        "Email": "Email",
        "Send us a message": "Envoyez-nous un message",
        "Im Interested In": "Je suis intéressé par",
        "PHOTOGRAPHY": "PHOTOGRAPHY",
        "VIDEOGRAPHY": "VIDEOGRAPHY",
        "BOTH PHOTO VIDEO": "PHOTO + VIDÉO",
        "First name": "Prénom",
        "Last name": "Nom",
        "Phone": "Téléphone",
        "Budget": "Budget",
        "Optional": "Optionnel",
        "Sending": "Envoyer",
        "Submit": "Envoyer",
        "Line and Noah": "Line et Noah sont un couple passionné d'image, de créativité et d'amour.",
        "Together we founded": "Ensemble, nous avons fondé",

        "about1": 'en 2019 et ont versé notre passion pour en faire notre "bébé."',
        "about2": "Nos films et photos sont inspirés par des méthodes et des compositions cinématographiques, et nous nous efforçons de refléter le style unique et la personnalité de chaque mariage à travers notre travail.",
        "about3": "Nous trouvons que la partie la plus gratifiante de notre travail est de capturer ces moments tendres et intemporels et de les transformer en une histoire qui sera racontée et vécue encore et encore.",
        "about4": ", nous sommes fiers de faire de chaque projet un reflet de notre passion et de notre amour. Nous capturons les moments les plus simples de la vie et les rendons magiques.",
        "about5": "Si vous voulez en savoir plus sur nous, écrivez-nous, nous serons heureux de vous parler.",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage. getItem("lng") || 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;