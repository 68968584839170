import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function Header() {
  const { t, i18n } = useTranslation();
  const [navbar, setNavbar] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setNavbar(false);
  }, [location]);

  return (
    <nav className="w-full bg-[#ffffff]/70 shadow fixed top-0 z-[999]">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <Link to="/">
              <h1 className="text-4xl font-noirblanc">
                <img
                  src="/images/VIEW_LOGO.png"
                  alt="view films logo"
                  className="h-10"
                />
              </h1>
            </Link>
            <div className="md:hidden">
              <button
                className="p-2 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="uppercase">
                <NavLink
                  to="/"
                  end
                  className={({ isActive }) =>
                    `${
                      isActive ? "border-b-2" : undefined
                    } hover:border-b-2 pb-1 Class border-black`
                  }
                >
                  {t('Home')}
                </NavLink>
              </li>
              <li className="uppercase">
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    `${
                      isActive ? "border-b-2" : undefined
                    } hover:border-b-2 pb-1 Class border-black`
                  }
                >
                  {t('About')}
                </NavLink>
              </li>
              <li className="uppercase">
                <NavLink
                  to="/portfolio"
                  className={({ isActive }) =>
                    `${
                      isActive ? "border-b-2" : undefined
                    } hover:border-b-2 pb-1 Class border-black`
                  }
                >
                {t('Portfolio')}
                  
                </NavLink>
              </li>
              <li className="uppercase">
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    `${
                      isActive ? "border-b-2" : undefined
                    } hover:border-b-2 pb-1 Class border-black`
                  }
                >
                {t('Contact')}
                </NavLink>
              </li>

              <li>
                <select
                  id="large"
                  className="bg-transparent block py-3 px-4 w-full text-base text-gray-900 bg-gray-50 rounded-lg border-0 outline-none"
                  onChange={(e) => {
                    i18n.changeLanguage(e.currentTarget.value);
                    localStorage.setItem("lng", e.currentTarget.value);
                  }}
                  value={i18n.resolvedLanguage}
                >
                  <option value="en">EN</option>
                  <option value="fr">FR</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
