import "./App.css";
import Header from "./Components/Header";
import Home from "./Components/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Portfolio from "./Components/Portfolio";
import Gallery from "./Components/Portfolio/Gallery";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/portfolio/:folder/:count" element={<Gallery />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Routes>
      <div className="fixed bottom-[40px] right-[40px] bg-black/50 rounded-[20px] shake-horizontal p-2" >
          <a href="https://www.instagram.com/viewfilms.w/" target="_blank" rel="noreferrer"><img src="/images/SVG/insta.svg" alt="instagram" width={60}/></a>
        </div>
        <div className=""></div>
    </Router>
  );
}

export default App;
