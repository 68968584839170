import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Gallery as G } from "react-grid-gallery";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { galleryVideo, videos } from "../../videos";

export default function Gallery() {
  let { folder, count } = useParams();
  const [images, setImages] = useState([]);

  useEffect(() => {
    let links = [];
    for (let i = 1; i <= count; i++) {
      links.push({ src: `/images/gallery/${folder}/${i}.jpg` });
    }
    setImages(links);
  }, []);

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex((old) => index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  console.log(images);
  return (
    <div className="mx-auto max-w-2xl py-16 px-4 lg:max-w-7xl lg:gap-x-8 lg:px-8 mt-20">
      {/* <h1 className="text-4xl font-noirblanc mb-14 text-center">{folder === "video" ? "Video" : "Album"}</h1> */}
      {folder === "video" ? (
        <>
          <iframe
            src={videos[count - 1].video}
            className="w-full"
            height="670"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            title={videos[count - 1].title}
          ></iframe>
          <h2 className="my-5 text-xl">
            <a href="https://vimeo.com/784594074">{videos[count - 1].title}</a>
          </h2>
        </>
      ) : (
        <>
          {galleryVideo[folder] && (
            <>
              <iframe
                src={galleryVideo[folder].video}
                className="w-full mb-3"
                height="670"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title={galleryVideo[folder].title}
              ></iframe>
            </>
          )}

          <G
            images={images}
            onClick={handleClick}
            enableImageSelection={false}
          />
          {!!currentImage && (
            /* @ts-ignore */
            <Lightbox
              mainSrc={currentImage.src}
              nextSrc={nextImage.src}
              nextSrcThumbnail={nextImage.src}
              prevSrc={prevImage.src}
              prevSrcThumbnail={prevImage.src}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
        </>
      )}
    </div>
  );
}
