import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const pictures = [
  {
    title: "IC PARIS",
    link: "IC-PARIS",
    count: 61,
    source: "/images/gallery/IC-PARIS/cover.jpg",
  },
  {
    title: "LYON",
    link: "LYON",
    count: 87,
    source: "/images/gallery/LYON/cover.jpg",
  },
  {
    title: "L&A",
    link: "LAURA",
    count: 32,
    source: "/images/gallery/LAURA/cover.jpg",
  },
  {
    title: "CHÂTEAU ROBERNIER",
    link: "CHATEAU-ROBERNIER",
    count: 59,
    source: "/images/gallery/CHATEAU-ROBERNIER/cover.jpg",
  },
  {
    title: "MARRAKECH",
    link: "MARRAKECH",
    count: 29,
    source: "/images/gallery/MARRAKECH/cover.jpg",
  },
  {
    title: "BOHEME",
    link: "BOHEME",
    count: 28,
    source: "/images/gallery/BOHEME/cover.jpg",
  },
  {
    title: "CAMARGUE",
    link: "CAMARGUE",
    count: 0,
    source: "/images/gallery/CAMARGUE/cover.jpg",
  },
  {
    title: "CAP FERRET",
    link: "CAP-FERRET",
    count: 42,
    source: "/images/gallery/CAP-FERRET/cover.jpg",
  },
  {
    title: "CHATEAU DE LA BARONNIE",
    link: "CHATEAU-DE-LA-BARONNIE",
    count: 25,
    source: "/images/gallery/CHATEAU-DE-LA-BARONNIE/cover.jpg",
  },
  // {
  //   title: "CHATEAU LAFITTE",
  //   link: "CHATEAU-LAFITTE",
  //   count: 0,
  //   source: "/images/gallery/CHATEAU-LAFITTE/cover.jpg",
  // },
  {
    title: "Սեր",
    link: "LOVE",
    count: 14,
    source: "/images/gallery/LOVE/cover.jpg",
  },
  {
    title: "CATALONA",
    link: "CATALONA",
    count: 26,
    source: "/images/gallery/CATALONA/cover.jpg",
  },
];

const videos = [
  // {
  //   title: "D&A",
  //   source: "/images/gallery/COVER/D-A.jpg",
  // },
  {
    title: "EL PARADISO",
    source: "/images/gallery/COVER/EL-PARADISO.jpg",
  },
  {
    title: "LA NUOVA DOLCE VITA",
    source: "/images/gallery/COVER/LA-NUOVA-DOLCE-VITA.jpg",
  },
  {
    title: "Passion In Province",
    source: "/images/gallery/COVER/Passion-In-Province.jpg",
  },
  // {
  //   title: "S&M",
  //   source: "/images/gallery/COVER/S-M.jpg",
  // },
  // {
  //   title: "Sound of Waves",
  //   source: "/images/gallery/COVER/Sound-of-Waves.jpg",
  // },
];

export default function Portfolio() {
  const { t } = useTranslation();

  return (
    <div className="mx-auto max-w-2xl py-16 px-4 lg:max-w-7xl lg:gap-x-8 lg:px-8 mt-20">
      <h1 className="text-4xl font-noirblanc mb-20 text-center">
        {t("Photography Portfolio")}
      </h1>

      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {pictures.map((picture) => (
          <li key={picture.source} className="relative">
            <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img
                src={picture.source}
                alt={picture.title}
                className="pointer-events-none object-cover group-hover:opacity-75"
              />
              <Link
                to={`/portfolio/${picture.link}/${picture.count}`}
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">
                  {t("View details for")} {picture.title}
                </span>
              </Link>
            </div>
            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
              {picture.title}
            </p>
          </li>
        ))}
        {videos.map((video, index) => (
          <li key={video.source} className="relative">
            <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img
                src={video.source}
                alt={video.title}
                className="pointer-events-none object-cover group-hover:opacity-75"
              />
              <Link
                to={`/portfolio/video/${index + 1}`}
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">
                  {t("View details for")} {video.title}
                </span>
              </Link>
            </div>
            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
              {video.title}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
