export const videos = [
  // {
  //   video: "https://player.vimeo.com/video/784594074?h=7f144d7e17",
  //   title: "D&A",
  // },
  {
    video: "https://player.vimeo.com/video/728563066?h=b307b9f90a",
    title: "EL PARADISO",
  },
  {
    video: "https://player.vimeo.com/video/728562296?h=aebba6f9e7",
    title: "La Nuova Dolce Vita",
  },
  {
    video: "https://player.vimeo.com/video/784577566?h=377e9b4265",
    title: "Passion in Province",
  },
  // {
  //   video: "https://player.vimeo.com/video/784581239?h=adfaa776bb",
  //   title: "S&M",
  // },
  // {
  //   video: "https://player.vimeo.com/video/573680689?h=d2e428a19e",
  //   title: "Sound of waves",
  // },
];

export const galleryVideo = {
  "CHATEAU-DE-LA-BARONNIE": {
    video: "https://player.vimeo.com/video/750062304?h=e53e0b3b43",
    title: "CHATEAU DE LA BARONNIE",
  },
  "CAP-FERRET": {
    video: "https://player.vimeo.com/video/755514477?h=00569abd62",
    title: "CAP FERRET",
  },
  "BOHEME": {
    video: "https://player.vimeo.com/video/784573547?h=42c324bab7",
    title: "BOHEME",
  },
  // "CHATEAU-LAFITTE": {
  //   video: "https://player.vimeo.com/video/784579951?h=b9b5e5a5b6",
  //   title: "CHATEAU LAFITTE",
  // },
  "CAMARGUE": {
    video: "https://player.vimeo.com/video/784575618?h=41a10e4f59",
    title: "CAMARGUE",
  },
  "MARRAKECH": {
    video: "https://player.vimeo.com/video/832939460?h=ab0acfca07",
    title: "MARRAKECH",
  },
  "LYON": {
    video: "https://www.youtube.com/embed/j7nnpotxC7E?si=V4v-2T9zvcxHmSvZ",
    title: "LYON",
  },
  "IC-PARIS": {
    video: "https://www.youtube.com/embed/X1mbsA9mNug?si=BSgAHDwaDodcorZE",
    title: "IC PARIS",
  },
};
