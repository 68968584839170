import './home.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

export default function Home() {
  const { t } = useTranslation();

  const isMobileOrIPhone = /Mobi|Android|iPhone/i.test(navigator.userAgent);

  const videoUrl = isMobileOrIPhone
    ? './videos/view-films-video-phone.mp4'
    : './videos/view-films-video-phone.mp4';

  return (
    <>
      <div className='h-screen bg-gradient-to-b from-transparent to-black/50 relative top-0'>
        <div className='mix-blend-overlay'>
          <ReactPlayer
            url={videoUrl}
            playing={true}
            loop={true}
            muted={true}
            width="100%"
            height="100%"
            playsinline  // Add this property
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        </div>
        <div className='p-5 text-white text-center absolute top-[60%] left-[50%] translate-x-[-50%] w-full'>
          <h1 className='text-4xl font-noirblanc'> {t('CREATE MAGIC')}</h1>
          <p className='tracking-[.25em] text-lg font-light'>{t('wedding film')}</p>
        </div>
      </div>
    </>
  );
}